<template>
  <div class="english">
    <div class="mainsection fade_in">
      <div class="headerLinks"></div>
      <div class="tabs clearfix">
        <div class="tabsLeft">
          <ul>
            <li><a>Page</a></li>
          </ul>
        </div>
        <div class="tabsRight">
          <ul>
            <li><a class="active">Read</a></li>
            <li><a>Edit</a></li>
            <li><a>View History</a></li>
          </ul>
        </div>
      </div>
      <div class="article">
        <h1>Adbc Inc.</h1>
        <p class="siteSub">From Adbc Inc.</p>
        <p class="roleNote">This section describes Adbc Inc.</p>

        <table class="rightbox">
          <caption class="rightbox-title">
            Adbc Inc.
          </caption>
          <tbody>
            <tr>
              <td colspan="2" class="rightbox-image">
                <img
                  alt="Adbc Inc."
                  src="../assets/img/adbc-logo.png"
                  decoding="async"
                  width="220"
                  height="82"
                  data-file-width="512"
                  data-file-height="191"
                />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <div
                  style="
                    display: inline-block;
                    line-height: 1.2em;
                    padding: 0.1em 0;
                  "
                >
                  Type
                </div>
              </th>
              <td>Incorporated</td>
            </tr>
            <tr>
              <th scope="row">
                <div
                  style="
                    display: inline-block;
                    line-height: 1.2em;
                    padding: 0.1em 0;
                  "
                >
                  Market information
                </div>
              </th>
              <td>Unlisted</td>
            </tr>
            <tr>
              <th scope="row">
                <div
                  style="
                    display: inline-block;
                    line-height: 1.2em;
                    padding: 0.1em 0;
                  "
                >
                  Headquarters location
                </div>
              </th>
              <td>
                <img
                  alt="日本の旗"
                  src="../assets/img/Japan.svg.png"
                  decoding="async"
                  width="25"
                  height="17"
                  class="thumbborder"
                  data-file-width="900"
                  data-file-height="600"
                />JPN<br />
                <span><b>zip</b></span
                >176-0001<br />
                2F Nikken Nerimabiru 1-20-8 Nerima, Nerima-ku, Tokyo-to Japan
              </td>
            </tr>
            <tr>
              <th scope="row">
                <div
                  style="
                    display: inline-block;
                    line-height: 1.2em;
                    padding: 0.1em 0;
                  "
                >
                  Founded
                </div>
              </th>
              <td>March 18, 2022 in Tokyo</td>
            </tr>
            <tr>
              <th scope="row">
                <div
                  style="
                    display: inline-block;
                    line-height: 1.2em;
                    padding: 0.1em 0;
                  "
                >
                  Industry
                </div>
              </th>
              <td>
                Software industry, information processing service industry, Web/Internet industry
              </td>
            </tr>
            <tr>
              <th scope="row">
                <div
                  style="
                    display: inline-block;
                    line-height: 1.2em;
                    padding: 0.1em 0;
                  "
                >
                  Corporate Number
                </div>
              </th>
              <td>0116-01-025817</td>
            </tr>
            <tr>
              <th scope="row">
                <div
                  style="
                    display: inline-block;
                    line-height: 1.2em;
                    padding: 0.1em 0;
                  "
                >
                  Business content
                </div>
              </th>
              <td>Design and construction of network systems, Network system monitoring and maintenance, Application design/development</td>
            </tr>
            <tr>
              <th scope="row">
                <div
                  style="
                    display: inline-block;
                    line-height: 1.2em;
                    padding: 0.1em 0;
                  "
                >
                  CEO
                </div>
              </th>
              <td>Yuki Matsumura</td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>Adbc Inc.</strong> Adbc is a company headquartered in
          Nerima-ku, Tokyo, that provides system development, infrastructure
          construction, WEB, and mobile services in the IT field. Established on March 18, 2022.
        </p>
        <div
          class="contentsPanel"
          v-bind:class="{ minimizedPanel: !panelStatus }"
        >
          <div class="hidePanel" v-show="panelStatus" @click="hidePanel()">
            [Hide]
          </div>
          <div class="showPanel" v-show="!panelStatus" @click="showPanel()">
            [Show]
          </div>
          <div class="contentsHeader">Contents</div>
          <ul v-bind:class="{ hidePanel: !panelStatus }">
            <li><span>1</span><a>Overview</a></li>
            <li><span>2</span><a>Corporate philosophy</a></li>
            <li><span>3</span><a>Service</a></li>
            <li><span>4</span><a>Recruitment</a></li>
            <li><span>5</span><a>History</a></li>
            <li><span>6</span><a>Contact information</a></li>
          </ul>
        </div>
        <div class="content_main" id="overview">
          <h2>Overview</h2>
          <p>
            Adbc Inc. provides services that combine advanced technologies to
            support clients' businesses. The management philosophy (mission
            statement) is to "organize information around the world and make it
            accessible and usable by people all over the world." The informal
            slogan (Employee Code of Conduct) is "Don't be evil". Founded in
            2022, the head office is located in Tokyo.
          </p>
        </div>
        <div class="content_main" id="philosophy">
          <h2>Corporate philosophy</h2>
          <p>
            With the goal of "enriching lives", the company focuses on cultivating "human resources who can look at work from a bird's-eye view and take optimal action, although work skills are important.
            " We believe that the most important thing is to reduce the "communication cost" that occurs in development and system operation, which requires more complicated communication than high IT skills, and to smoothly carry out work. 
            Regardless of the IT field, these skills are indispensable in any industry or occupation, and it is no exaggeration to say that they are necessary skills in life. 
            We would like to create a good cycle in which work can be carried out smoothly, eliminating unnecessary work and increasing private time.
          </p>
        </div>
        <div class="content_main" id="service">
          <h2>Service</h2>
          <p>We offer the following services.</p>
          <ul>
            <li>System development/support</li>
            <li>System consulting</li>
            <li>Infrastructure construction/design</li>
            <li>Security diagnosis</li>
          </ul>
        </div>
        <div class="content_main" id="recruitment">
          <h2>Recruitment</h2>
          <ul>
            <li>Recruitment type: Engineer/general office work/sales</li>
            <li>Employment classification: regular employee / contract employee</li>
            <li>Work location: Telework (place specified by the project)</li>
            <li>Working hours: Flex (8h/Day)</li>
            <li>Insurance: Social insurance</li>
            <li>Welfare: teaching aids purchase/tuition assistance</li>
            <li>Selection process: (1) Document screening (2) Interview</li>
            <li>Salary: From 300,000 yen (for engineers, depending on ability)</li>
          </ul>
        </div>
        <div class="content_main" id="history">
          <h2>History</h2>
          <ul>
            <li>Established March 18, 2022</li>
          </ul>
        </div>
        <div class="content_main" id="contact">
          <h2>Contact information</h2>
          <p>For inquiries about recruitment and services, please enter the following reCAPTCHA and send an email to the displayed email address.</p>
          <div id="validate" class="fade_in" v-show="captchaStatus">
            <div class="container">
              <div id="captcha" class="captcha">
                {{ theCaptcha }}
              </div>
              <div class="restart">
                <button class="link-style-btn" @click="createCaptcha()">
                  Change the display characters
                </button>
              </div>
              <div class="input">
                <input
                  type="text"
                  v-model="reCaptcha"
                  maxlength="6"
                  style="width: 230px"
                  placeholder="Please enter the display characters."
                />
              </div>
              <div style="padding-top: 5px">
                <input
                  class="confirm_btn"
                  type="button"
                  value="Check the contact information"
                  style="width: 240px"
                  @click="validateCaptcha()"
                />
              </div>
              <div class="errmsg fade_in" style="color: red">{{ errMsg }}</div>
            </div>
          </div>
          <input
            class="btn_mail"
            id="btn_mail"
            type="button"
            value="Click here and check the details."
            style="width: 250px"
            @click="showCaptcha()"
            v-show="detailBtnStatus"
          />
        </div>
        <div id="contact_info fade_in" v-show="contactStatus">
          {{ contactInfo }}
        </div>
        <div class="pagefooter">
          Last updated April 1, 2022 (Friday) 17:01 (UTC if the date and time
          are not set in the personal settings).
          <div class="footerlinks">
            Copyright ©2022-2023 Adbc Inc. All Rights Reserved
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "el-eng",
  data: function () {
    return {
      detailBtnStatus: true,
      panelStatus: true,
      captchaStatus: false,
      contactStatus: false,
      theCaptcha: this.createCaptcha(),
      reCaptcha: "",
      errMsg: null,
      contactInfo: "sales@adbcc.jp",
    };
  },
  methods: {
    hidePanel() {
      this.panelStatus = false;
    },
    showPanel() {
      this.panelStatus = true;
    },
    showCaptcha() {
      this.captchaStatus = true;
      this.detailBtnStatus = false;
    },
    createCaptcha() {
      let captcha = new Array();
      for (let q = 0; q < 6; q++) {
        if (q % 2 == 0) {
          captcha[q] = String.fromCharCode(Math.floor(Math.random() * 26 + 65));
        } else {
          captcha[q] = Math.floor(Math.random() * 10 + 0);
        }
      }
      return (this.theCaptcha = captcha.join(""));
    },
    validateCaptcha() {
      if (this.reCaptcha == "") {
        this.errMsg = "The input field is required.";
      } else if (this.reCaptcha != this.theCaptcha) {
        this.errMsg = "The value of input field is incorrect.";
      } else {
        this.errMsg = "";
        this.panelStatus = false;
        this.captchaStatus = false;
        this.contactStatus = true;
        console.log(this.contactStatus);
      }
    },
  },
};
</script>
